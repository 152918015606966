import { ready } from 'ext/utils'
import LibSlicksStepsSlider from 'global/lib/slicks/steps_slider'

const theClock = () => {
  const clockBlock = document.querySelector('[data-clock]')
  clockBlock.innerText = new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })
}

ready(() => {
  new LibSlicksStepsSlider

  theClock()
  setInterval(theClock, 10000)
})
